<template>
    <div class="animated fadeIn">
        <b-card title="ความถี่การใช้งาน">

            <b-form @submit.prevent="searchFn">
                <div class="row">
                    <div class="col-sm-6 col-md-6 col-lg-6 col-xl-3 my-1">
                        {{ $t("message.year") }} :
                        <br />
                        <b-form-select v-model="timeSel" :options="timeOpt" v-on:change="selectTime" />
                    </div>

                    <div class="col-sm-6 col-md-6 col-lg-6 col-xl-3 my-1">
                        {{ $t("message.machineIdFarm") }} :
                        <br />
                        <multi-list-select :list="machineData" option-value="machineId" option-text="name"
                            :selected-items="selectedMachine" :placeholder="$t('message.all')"
                            @select="onSelectMachine">
                        </multi-list-select>
                    </div>

                    <div class="col-sm-6 col-md-6 col-lg-6 col-xl-3 my-1">
                        {{ $t("message.CustomerStatus") }} :
                        <br />
                        <multi-list-select :list="$t('message.arrays.caseCustomerStatus')" option-value="value"
                            option-text="text" :selected-items="selectedCustomerStatus" :placeholder="$t('message.all')"
                            @select="onSelectCustomerStatus">
                        </multi-list-select>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-6 col-md-6 col-lg-6 col-xl-3 my-1">
                        {{ $t("message.selectDateTime") }} :
                        <br />
                        <date-time-picker v-model="searchData.datetime"></date-time-picker>
                    </div>

                    <div class="col-sm-6 col-md-6 col-lg-6 col-xl-3 my-1">
                        StatusUse :
                        <br />
                        <multi-list-select :list="$t('message.arrays.caseStatusUse')" option-value="value"
                            option-text="text" :selected-items="selectedStatusUse" :placeholder="$t('message.all')"
                            @select="onSelectStatusUse">
                        </multi-list-select>
                    </div>

                    <div class="col-sm-6 col-md-6 col-lg-6 col-xl-3 my-1">
                        {{ $t("message.refNumber") }} :
                        <br />
                        <b-form-input v-model="searchData.refNumber" type="number"/>
                    </div>

                    <div class="col-sm-6 col-md-6 col-lg-6 col-xl-2 my-3">
                        <b-btn class="col-sm-12 col-md-6 my-1" variant="primary"
                            v-on:click="searchFn({ excel: false })">
                            <i class="fa fa-search"></i>
                            &nbsp;{{ $t("message.search") }}
                        </b-btn>

                        <b-btn class="col-sm-12 col-md-6 my-2 " v-on:click="searchFn({ excel: true })" variant="success"
                            v-if="$isRole('owner', role_id)">
                            <i class="icon-docs"></i>
                            &nbsp;{{ $t("message.export") }}
                        </b-btn>
                    </div>
                </div>
                <br />
            </b-form>

            <div class="row">
                <div class="col-sm-6 col-md-6 col-lg-6 col-xl-2 my-1"> จำนวนอันดับ : {{ totalRows | formatNumber }}
                </div>
                <div class="col-sm-6 col-md-6 col-lg-6 col-xl-2 my-1"> {{ $t('message.totalRevenue') }} : {{ summary.all
                        | formatNumber
                }}</div>
            </div>
            <br />

            <!-- {{rowData.rows}} -->

            <div class="table-responsive">
                <Table stripe row-key="transactionId" size="small" :columns="column" :data="rowData.rows" />
            </div>
            <br />
            <Page :total="totalRows" :page-size="itemperPage" @on-change="changePage" :current="currentPage" />


        </b-card>
    </div>
</template>
<script>

import Vue from 'vue'
import webServices from '../../script'
// import DateTimePicker from '../../components/DateTimePicker/Custom.vue'
// import DateTimePicker from "../../components/DateTimePicker/DatePicker.vue";
// import DateTimePicker from '../../components/DateTimePicker/Custom.vue'
import DateTimePicker from "../../components/DateTimePicker/MonthCustom";
import FileSaver from 'file-saver'
import { MultiSelect, MultiListSelect } from '../../custom_modules/search-select'
import moment from 'moment'
import VueJsonPretty from 'vue-json-pretty'

//https://stackoverflow.com/questions/44538110/how-to-format-numbers-in-vuejs
var numeral = require('numeral')

Vue.filter('formatNumber', function (value) {
    return numeral(value).format('0,0') // displaying other groupings/separators is possible, look at the docs
})

import Multiselect from 'vue-multiselect'

export default {
    name: "orders",
    components: {
        DateTimePicker,
        MultiSelect,
        MultiListSelect,
        VueJsonPretty,
        Multiselect,

    },
    data() {
        return {
            api: {},
            timeSel: 0,
            timeOpt: [
                { value: 0, text: moment().format('YYYY') },
                { value: 1, text: '<2022'}
            ],
            role_id: window.localStorage.getItem("roleID"),
            userBusiness: window.localStorage.getItem("business"),
            language: window.localStorage.getItem("language"),
            rowData: {},
            totalRows: 0,
            totalSubRows: 0,
            currentPage: 1,
            itemperPage: 50,
            summary: {},
            selectedProduct: [],
            selectedMachine: [],
            selectedStatusUse: [],
            selectedCustomerStatus: [],
            machineData: [],
            selectedId: {},
            searchData: {
                machineId: '',
                datetime: [moment().startOf('month').toDate(), moment().endOf('month').toDate()],
            },
            value: [],
            column: [
                {
                    title: this.$t('message.No'),
                    key: 'index',
                    minWidth: 80,
                    align: 'center'
                },
                {
                    title: this.$t('message.machineIdFarm'),
                    key: 'machineId',
                    minWidth: 100,
                    align: 'center'
                },
                {
                    title: this.$t('message.refNumber'),
                    key: 'refNumber',
                    minWidth: 140,
                    align: 'right'
                },
                {
                    title: 'createdAt',
                    minWidth: 160,
                    align: 'center',
                    render: (h, params) => {
                        return h('span', moment(params.row.CustomerCredits.createdAt).format('YYYY-MM-DD HH:mm'))
                    }
                },
                {
                    title: 'startDate',
                    minWidth: 160,
                    align: 'center',
                    render: (h, params) => {
                        return h('span', moment(params.row.startDate).format('YYYY-MM-DD HH:mm'))
                    }
                },
                {
                    title: 'lastDate',
                    minWidth: 160,
                    align: 'center',
                    render: (h, params) => {
                        return h('span', moment(params.row.lastDate).format('YYYY-MM-DD HH:mm'))
                    }
                },
                {
                    title: this.$t('message.transactions'),
                    key: 'trans',
                    minWidth: 116,
                    align: 'right',
                    render: (h, params) => {
                        return h('span', Vue.filter('formatNumber')(params.row.trans))
                    }
                },
                {
                    title: this.$t('message.insert'),
                    key: 'total',
                    minWidth: 100,
                    align: 'right',
                    render: (h, params) => {
                        return h('span', Vue.filter('formatNumber')(params.row.total))
                    }
                },
                {
                    title: 'จำนวนวัน',
                    key: 'amountOfDays',
                    minWidth: 100,
                    align: 'right',
                },
                {
                    title: 'จำนวนเดือน',
                    key: 'amountOfMonth',
                    minWidth: 120,
                    align: 'right',
                },
                // {
                //     title: 'จำนวนเดือน',
                //     key: 'monthUsed',
                //     minWidth: 110,
                //     align: 'right',
                // },
                // {
                //     title: 'เดือนล่าสุดที่ใช้',
                //     key: 'lastDayOfUse',
                //     minWidth: 100,
                //     align: 'right',
                // },
                {
                    title: 'ประเภทลูกค้า',
                    // key: 'UseStatus',
                    minWidth: 120,
                    align: 'right',
                    render: (h, params) => {
                        return h('span', params.row.CustomerCredits.CustomerStatus)
                    }
                },
                {
                    title: 'สถานะใช้งาน',
                    key: 'UseStatus',
                    minWidth: 120,
                    align: 'right'
                },
                {
                    title: this.$t('message.detail'),
                    // slot: 'action',
                    width: 100,
                    align: 'center',
                    render: this.renderDetail
                }
            ],
        };
    },
    async mounted() {
        await this.setParams(this.$route.query);
        await this.getMachine();
        if (
            !this.$isRole("admin", this.role_id) &&
            !this.$isRole("callcenter", this.role_id)
        ) {
            await this.getMonthly();
        }
    },

    methods: {
        selectTime(value) {
            if (value > 0) {
                this.api.defaults.baseURL = '/old/' + this.axios.defaults.baseURL
            } else {
                this.api.defaults.baseURL = this.axios.defaults.baseURL
            }
        },

        setParams(query) {
            if (Object.keys(query).length != 0) {
                // console.log(query)
                this.fromdate = query.from;
                this.todate = query.to;
                this.searchData.datetime = [query.from, query.to];
                this.searchData.productId = query.productId || "";
                this.searchData.machineId = query.machineId || "";
            }
        },

        isAdmin(role) {
            return webServices.isAdmin(role)
        },

        isDealer(role) {
            return webServices.isDealer(role)
        },

        changePage(PageNum) {
            // window.scrollTo(0, 0)
            this.getMonthly(PageNum)
        },

        renderDetail(h, params) {
            return h('Button', {
                props: {
                    type: 'primary',
                    size: 'small',
                    icon: 'ios-expand'
                },
                style: {
                    marginRight: '5px'
                },
                on: {
                    click: () => {
                        this.detail(params.row)
                    }
                }
            })
        },

        getMachine() {
            this.$Progress.start();
            this.axios
                .get(`/machines/list`)
                .then((res) => {
                    this.$Progress.finish();
                    this.machineData = res.data;
                    if (this.machineData.length == 1) {
                        this.selectedMachine = this.machineData;
                    }
                })
                .catch((err) => {
                    this.$Progress.fail();
                    this.$toast.error({
                        title: "ERROR",
                        message: {
                            error: err.response.data,
                            code: err.response.status,
                            text: err.response.statusText,
                        },
                    });
                    console.log("error @machine");
                    console.log(err);
                    if (err.response.status === 401) {
                        webServices.tokenExpire();
                    }
                });
        },

        showAlert_ChooseThanOneBranch() {
            if (window.localStorage.getItem("language") == 'th') {
                var title = "โปรดเลือกช่วงเวลาใหม่อีกครั้ง"
                var text = "เลือกมากกว่า 1 สาขา \n " + "ไม่สามารถเลือกเดือนได้มากกว่า 2 เดือน\n"
                var confirmButton = "ปิด"
            } else {
                var title = "Please select a new time again"
                var text = "Choose than 1 branch, \n " + "Cannot select more than 2 months.\n"
                var confirmButton = "Close"
            }

            this.$swal({
                title: title,
                html: '<pre>' + text + '</pre>',
                // text: text ,
                icon: 'warning',
                // showCancelButton: true,
                confirmButtonColor: '#3085d6',
                // cancelButtonColor: '#d33',
                confirmButtonText: confirmButton
            })
        },

        monthDiff(d1, d2) {
            var months;
            months = (d2.getFullYear() - d1.getFullYear()) * 12;
            months -= d1.getMonth();
            months += d2.getMonth();
            return months <= 0 ? 0 : months;
        },

        searchFn(opt = { excel: false }) {
            if (this.searchData.datetime !== '') {
                this.fromdate = moment(this.searchData.datetime[0]).format('YYYY-MM-DD HH:mm:ss')
                // this.todate = moment(this.searchData.datetime[1]).format('YYYY-MM-DD HH:mm:ss')
                this.todate = moment(this.searchData.datetime[1]).endOf('date').format("YYYY-MM-DD HH:mm:ss");

            } else {
                this.fromdate = ''
                this.todate = ''
            }

            if (this.searchData.type != 'washing') {
                this.transactionType = 'ALL'
            }

            var localStorageUser = JSON.parse(window.localStorage.getItem("users"))
            var datetimeRequest = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')

            this.searchData.userName = localStorageUser.userName
            this.searchData.userRole = localStorageUser.role
            this.searchData.userType = localStorageUser.type
            this.searchData.userConfiglanguage = localStorageUser.config.language
            this.searchData.datetimeRequest = datetimeRequest

            var dateStart = new Date(this.searchData.datetime[0])
            var dateEnd = new Date(this.searchData.datetime[1])

            // case เลือก 1 สาขา จะเลือกช่วงเวลาได้มากสุด 1 ปี
            if (this.searchData.machineId.length == 1 || window.localStorage.getItem("roleID") == 'dealer') {
                console.log("เลือก 1 สาขา หรือ dealer")
                if (this.monthDiff(dateStart, dateEnd) < 12) {
                    if (!opt.excel) {
                        this.getMonthly(1)
                    } else {
                        this.exportExcel()
                    }

                } else {
                    console.log("เลือกเดือนได้สูงสุด 12 เดือน")
                    this.showAlert_ChooseOneBranch() // เลือกเดือนได้สูงสุด 12 เดือน
                }
                // case เลือกมากกว่า 1 สาขา จะเลือกช่วงเวลาได้มากสุด 2 เดือน
            } else {
                console.log("เลือกมากกว่า 1 สาขา")
                if (this.monthDiff(dateStart, dateEnd) < 2) {
                    this.getMonthly(1)
                    if (!opt.excel) {
                        this.getMonthly(1)
                    } else {
                        this.exportExcel()
                    }

                } else {
                    console.log("เลือกเดือนได้สูงสุด 2 เดือน")
                    this.showAlert_ChooseThanOneBranch() // เลือกเดือนได้สูงสุด 2 เดือน
                }
            }
        },

        async getMonthly(page = 1) {
            this.$Progress.start()

            this.currentPage = page
            console.log('this.searchData.statusUse', this.searchData.statusUse)

            const rows = this.itemperPage
            const params = {
                ...this.searchData,
                page: page,
                rows: rows,
                // machine: this.selectedMachine,
                // from: this.searchData.from = moment(this.searchData.datetime[0]).format("YYYY-MM-DD HH:mm:ss"),
                // to: this.searchData.to = moment(this.searchData.datetime[1]).format("YYYY-MM-DD HH:mm:ss"),
                from: (this.searchData.from = moment(this.searchData.datetime[0]).startOf("month").format("YYYY-MM-DD HH:mm:ss")),
                to: (this.searchData.to = moment(this.searchData.datetime[1]).endOf("month").format("YYYY-MM-DD HH:mm:ss")),
                // type: "washing",
                // notThinkStatus: [
                //     "CANCEL_BY_MC_FAIL",
                //     "CANCEL_PAYMENT",
                //     "CANCEL_TIMEOUT",
                // ],
            };


            // delete params.id
            // delete params.datetime

            await this.axios
                .get(`/analyze/usagefrequency`, {
                    params,
                })
                .then((res) => {
                    //   this.SaleByType = res.data.data;
                    this.rowData = res.data
                    console.log('this.rowData', this.rowData)


                    if (res.data) {
                        this.totalRows = res.data.trans
                        this.summary.all = res.data.total

                        for (var i = 0; i < this.rowData.rows.length; i++) {
                            if (page > 1) {
                                this.rowData.rows[i]['index'] = (50 * (page - 1)) + (i + 1)
                            } else {
                                this.rowData.rows[i]['index'] = i + 1
                            }
                        }
                    }
                    this.$Progress.finish()

                })
                .catch((err) => {
                    this.$Progress.fail();
                    console.log(err);
                    this.$toast.error({
                        title: "ERROR",
                        message: err,
                    });
                    if (err.response.status === 401) {
                        webServices.tokenExpire();
                    }
                });
        },

        detail(data) {
            console.log('log push page order', data)
            const params = {
                // ...this.searchData,
                ...data,
                // from: moment(this.searchData.datetime[0]).format('YYYY-MM-DD HH:mm:ss'),
                // to: moment(this.searchData.datetime[1]).format('YYYY-MM-DD HH:mm:ss'),
                from: (this.searchData.from = moment(this.searchData.datetime[0]).startOf("month").format("YYYY-MM-DD HH:mm:ss")),
                to: (this.searchData.to = moment(this.searchData.datetime[1]).endOf("month").format("YYYY-MM-DD HH:mm:ss")),
                select_machine: 1,

                // productId: productId,
                // from: moment(this.filters.date[0]).format('YYYY-MM-DD HH:mm:ss'),
                // to: moment(this.filters.date[1]).format('YYYY-MM-DD HH:mm:ss')
            }

            // if (this.filters.machineId) {
            //   params.machineId = this.filters.machineId
            // }
            this.$router.push({ path: '/orders', query: params })
        },

        onSelectMachine(items) {
            // console.log(items)
            this.selectedMachine = items;
            this.searchData.machineId = this.selectedMachine.map(
                (data) => data.machineId
            );
        },

        onSelectStatusUse(items) {
            // console.log('onSelectType', items)
            this.selectedStatusUse = items;
            this.searchData.statusUse = this.selectedStatusUse.map(
                (data) => data.value
            );
        },

        onSelectCustomerStatus(items) {
            // console.log('onSelectType', items)
            this.selectedCustomerStatus = items;
            this.searchData.customerStatus = this.selectedCustomerStatus.map(
                (data) => data.value
            );
        },


    },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css">

</style>